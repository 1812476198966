import React, { useState, useEffect, useContext } from 'react'
import AdminHeader from '../layout/AdminHeader';
import Footer from '../layout/Footer';
import CustomerSidbar from '../components/CustomerSidbar';
import SliderHomeSec from '../components/SliderHomeSec';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import CircularProgressBar from '../components/CircularProgressBar';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { Is_Login } from '../helper/IsLogin';
import { CourseContext } from '../context/CourseContext';
import { getServerURL, getImageBaseUrl } from '../helper/envConfig';
import api from '../helper/api';
import { useLocation } from 'react-router-dom';
import { decryptFromStoreLoc } from '../helper/localStore';
import { CourseCardSkeleton } from '../components/SkeletonLayouts';
import AccessCourse from '../components/AccessCourse';
import { capitalizeWords } from '../helper/constants';



const BusinessFamily = () => {
    const { user, setUser, setLoder, listCourse, getCourses } = useContext(CourseContext);

    const history = useNavigate()
    const isLoggedIn = Is_Login();
    const serverURL = getServerURL();
    const [carouselItems, setCarouselItems] = useState([]);


    const featuredresponsiveOptions = {
        0: {
            items: 1,
        },

        600: {
            items: 1,
        },

        1000: {
            items: 2,
        },

        1440: {
            items: 2,
        },
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [badges, setBadges] = useState({});

    const getQuizesAns = async () => {
        try {
            if (isLoggedIn) {
                setLoder(true)
                const apiTyp = isLoggedIn ? api.getWithToken : api.get;
                const response = await apiTyp(`${serverURL}gamifications/my-badges/`);
                if (response?.data?.data?.length) {
                    setBadges(response.data)
                }
                setLoder(false)
            }

        } catch (error) {
            setLoder(false)
            console.log(error);
        }
    };

    useEffect(() => {
        if (listCourse?.results) {
          setCarouselItems(listCourse?.results);
        }
      }, [listCourse?.results]);


      useEffect(() => {
        getCourses();
      }, []);

    useEffect(() => {
        // Call the function to get quiz results
        getQuizesAns();

        // Retrieve and decrypt stored information
        const decryptedInfo = decryptFromStoreLoc('user');
        if(decryptedInfo?.role == 6 || decryptedInfo?.role == 11 || decryptedInfo?.role == 13){
            setUser(decryptedInfo)
        }else{
            history("/")
        }

    }, [isLoggedIn]); // Add serverURL to the dependency array

    const handelRedirect = () => {
        history("/customer-courses")
    }

    function CourseCardContent({ data , url }) {
        return (
        //   <Link to={`/course-details/${data.course_id}`} >
          <div className="card featured-card overflow-hidden shadow border-0 position-relative">
            <img
              src="./featured-courses/featured01.jpg"
              className="featured-img"
              alt=""
            />
            <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
              {data.type_name}
            </span>
            <div className="card-body">
              <span className="badge badge-light rounded-2 fw-400 fs-6">
                {capitalizeWords(data?.tags_list[0]?.name)}
              </span>
              <h5>{data.name}</h5>
              {/* <div className="row justify-content-between align-items-center mt-4 mb-1">
                <p className="fw-500 text-black mb-0 col-6 fs-7">
                  Beginner Boost
                </p>
                <div className="col-6 d-flex justify-content-between align-items-center">
                  <ul className="rectangle d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                    <li className="active" />
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4">
                <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
                  1K
                </span>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src="./featured-courses/calibration.jpg"
                    className="wh-25"
                    alt=""
                  />
                  <p className="mb-0 text-black fw-400 fs-7">
                    Graduated with Glory
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 mt-3">
                <span className="badge badge-light rounded-2 fs-5 fw-600">
                  213
                </span>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src="/featured-courses/desktop.jpg"
                    className="wh-25"
                    alt=""
                  />
                  <p className="mb-0 text-black fw-400 fs-7">
                    Signed Up for Success
                  </p>
                </div>
              </div> */}
              <AccessCourse url={url} is_accessible={data?.is_accessible} />
            </div>
          </div>
        //   </Link>
        );
    }

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <CustomerSidbar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <SliderHomeSec />
                                        <h2 className="text-black fw-600 pt-5 pt-md-0 pb-0 pb-md-4">
                                            Welcome <span className="text-capitalize">{user?.username}</span>,
                                        </h2>
                                        <div className="row mb-5">
                                            <div className="col-12 col-lg-8">
                                                <div className="row gy-4">
                                                    {/* <div className="col-12 col-md-7">
                                                        <div className="card border-0 h-100">
                                                            <div className="px-3 pt-4 d-flex justify-content-between align-items-center">
                                                                <p className="fs-18 fw-600 mb-0">Completed Course</p>
                                                                <Link to="">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={16}
                                                                        height={4}
                                                                        viewBox="0 0 16 4"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M8.08496 0.46875C7.74541 0.46875 7.41349 0.569439 7.13116 0.758083C6.84883 0.946727 6.62879 1.21485 6.49885 1.52856C6.36891 1.84226 6.33491 2.18745 6.40115 2.52048C6.4674 2.8535 6.63091 3.15941 6.871 3.39951C7.1111 3.6396 7.41701 3.80311 7.75003 3.86936C8.08306 3.9356 8.42825 3.9016 8.74195 3.77166C9.05565 3.64172 9.32378 3.42167 9.51243 3.13935C9.70107 2.85702 9.80176 2.5251 9.80176 2.18555C9.80176 1.73022 9.62088 1.29355 9.29892 0.971588C8.97696 0.649627 8.54028 0.46875 8.08496 0.46875ZM2.07617 0.46875C1.73662 0.46875 1.4047 0.569439 1.12237 0.758083C0.840046 0.946727 0.619999 1.21485 0.490059 1.52856C0.360119 1.84226 0.326121 2.18745 0.392364 2.52048C0.458607 2.8535 0.622115 3.15941 0.862214 3.39951C1.10231 3.6396 1.40822 3.80311 1.74124 3.86936C2.07427 3.9356 2.41946 3.9016 2.73316 3.77166C3.04687 3.64172 3.31499 3.42167 3.50364 3.13935C3.69228 2.85702 3.79297 2.5251 3.79297 2.18555C3.79297 1.73022 3.61209 1.29355 3.29013 0.971588C2.96817 0.649627 2.5315 0.46875 2.07617 0.46875ZM14.0938 0.46875C13.7542 0.46875 13.4223 0.569439 13.1399 0.758083C12.8576 0.946727 12.6376 1.21485 12.5076 1.52856C12.3777 1.84226 12.3437 2.18745 12.4099 2.52048C12.4762 2.8535 12.6397 3.15941 12.8798 3.39951C13.1199 3.6396 13.4258 3.80311 13.7588 3.86936C14.0918 3.9356 14.437 3.9016 14.7507 3.77166C15.0644 3.64172 15.3326 3.42167 15.5212 3.13935C15.7099 2.85702 15.8105 2.5251 15.8105 2.18555C15.8105 1.73022 15.6297 1.29355 15.3077 0.971588C14.9857 0.649627 14.5491 0.46875 14.0938 0.46875Z"
                                                                            fill="#6A6B70"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                            <div className="card-body p-5">
                                                                <div className="mb-3">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="mb-0 text-muted fs-12 fw-500">Java Code</p>
                                                                        <p className="mb-0 text-black fs-12 fw-500">75/100</p>
                                                                    </div>
                                                                    <div
                                                                        className="progress mt-3 rounded-start-0 progress-bg-1-light"
                                                                        role="progressbar"
                                                                        aria-label="Basic example"
                                                                        aria-valuenow={25}
                                                                        aria-valuemin={0}
                                                                        aria-valuemax={100}
                                                                    >
                                                                        <div className="progress-bar progress-1 " />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="mb-0 text-muted fs-12 fw-500">Design Basic</p>
                                                                        <p className="mb-0 text-black fs-12 fw-500">65/100</p>
                                                                    </div>
                                                                    <div
                                                                        className="progress mt-3 rounded-start-0 progress-bg-2-light"
                                                                        role="progressbar"
                                                                        aria-label="Basic example"
                                                                        aria-valuenow={25}
                                                                        aria-valuemin={0}
                                                                        aria-valuemax={100}
                                                                    >
                                                                        <div className="progress-bar progress-2" />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="mb-0 text-muted fs-12 fw-500">Team Building</p>
                                                                        <p className="mb-0 text-black fs-12 fw-500">30/100</p>
                                                                    </div>
                                                                    <div
                                                                        className="progress mt-3 rounded-start-0 progress-bg-3-light"
                                                                        role="progressbar"
                                                                        aria-label="Basic example"
                                                                        aria-valuenow={25}
                                                                        aria-valuemin={0}
                                                                        aria-valuemax={100}
                                                                    >
                                                                        <div className="progress-bar progress-3" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-12 col-md-5">
                                                        <div className="card border-0 h-100">
                                                            <div className="px-3 pt-4 d-flex justify-content-between align-items-center">
                                                                <p className="fs-18 fw-600 mb-0">Course Activites</p>
                                                            </div>
                                                            <div className="card-body p-4">
                                                                <CircularProgressBar />
                                                                <div className="global m-auto" data-pie='{ "percent": 75 }'>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                                    <div className="d-flex align-items-center gap-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={13}
                                                                            height={13}
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                        >
                                                                            <circle
                                                                                cx="6.6495"
                                                                                cy="6.48006"
                                                                                r="6.12996"
                                                                                fill="#0177FB"
                                                                            />
                                                                        </svg>
                                                                        <p className="mb-0 fs-11 fw-500 text-black">Process</p>
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-3">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={13}
                                                                            height={13}
                                                                            viewBox="0 0 13 13"
                                                                            fill="none"
                                                                        >
                                                                            <circle
                                                                                cx="6.59871"
                                                                                cy="6.48006"
                                                                                r="6.12996"
                                                                                fill="#E8F3FF"
                                                                            />
                                                                        </svg>
                                                                        <p className="mb-0 fs-11 fw-500 text-black">In Process</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {badges.data?.length &&
                                                        <div className="col-12">
                                                            <div className="card border-0 h-100">
                                                                <div className="px-3 pt-4 d-flex justify-content-between align-items-center">
                                                                    <p className="fs-18 fw-600 mb-0">My Badges</p>
                                                                </div>
                                                                <div className="card-body p-4 badge-list-mail2">
                                                                    <div className="row g-4 pb-4 pb-md-5">

                                                                    {badges.data.slice(0, 8).map((data) => (
                                                                            <div className="col-6 col-sm-6 col-md-6 col-lg-3 border-right-badge">
                                                                                <div className='badge-image'>
                                                                                    <img src={getImageBaseUrl() + data?.image} alt="" />
                                                                                </div>
                                                                                <p className='badge-title'> {data.name}</p>
                                                                                <p className='badge-subtitle'>{data.created_at}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className='view-all-bottom-button d-flex justify-content-center'>
                                                                        <Link onClick={handleShow}>View all</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {/* <div className="col-12">
                                                        <div className="card border-0 h-100">
                                                            <div className="px-3 pt-4 d-flex justify-content-between align-items-center">
                                                                <p className="fs-18 fw-600 mb-0">Upcoming Mandatory Courses</p>
                                                                 <select className="form-select border-0 week-select w-auto">
                                                                    <option selected="">Week</option>
                                                                    <option value={1}>One</option>
                                                                    <option value={2}>Two</option>
                                                                    <option value={3}>Three</option>
                                                                </select>
                                                            </div>
                                                            <div className="card-body p-4">
                                                                <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 mb-4">
                                                                    <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                        <div className="d-flex align-items-center gap-4">
                                                                            <img
                                                                                src="./admin-images/png/animation-img.png"
                                                                                className="animation-img"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                        <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                            <div>
                                                                                <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                                <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                    12 Mini Chapters
                                                                                </p>
                                                                                <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                    Due Date - Apr 10
                                                                                </p>
                                                                            </div>
                                                                            <Link to="" className="docs-creator">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={5}
                                                                                    height={17}
                                                                                    viewBox="0 0 5 17"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                        fill="#646C78"
                                                                                    />
                                                                                </svg>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 mb-4">
                                                                    <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                        <div className="d-flex align-items-center gap-4">
                                                                            <img
                                                                                src="./admin-images/png/animation-img.png"
                                                                                className="animation-img"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                        <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                            <div>
                                                                                <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                                <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                    12 Mini Chapters
                                                                                </p>
                                                                                <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                    Due Date - Apr 10
                                                                                </p>
                                                                            </div>
                                                                            <Link to="" className="docs-creator">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={5}
                                                                                    height={17}
                                                                                    viewBox="0 0 5 17"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                        fill="#646C78"
                                                                                    />
                                                                                </svg>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center justify-content-center justify-content-md-between animation-main p-2 p-md-3 rounded-3 mb-4">
                                                                    <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                                                                        <div className="d-flex align-items-center gap-4">
                                                                            <img
                                                                                src="./admin-images/png/animation-img.png"
                                                                                className="animation-img"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5 col-md-8 col-lg-9 col-xl-10">
                                                                        <div className="d-flex justify-content-between align-items-center px-0 px-lg-3">
                                                                            <div>
                                                                                <p className="mb-0 fs-6 fw-400 text-black">Strategies for Selling Success</p>
                                                                                <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                    12 Mini Chapters
                                                                                </p>
                                                                                <p className="mb-0 fs-12 fw-400 text-muted">
                                                                                    Due Date - Apr 10
                                                                                </p>
                                                                            </div>
                                                                            <Link to="" className="docs-creator">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width={5}
                                                                                    height={17}
                                                                                    viewBox="0 0 5 17"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M2.17341 3.85287C2.57546 3.85287 2.96848 3.74427 3.30277 3.5408C3.63706 3.33733 3.8976 3.04813 4.05146 2.70977C4.20532 2.37141 4.24557 1.99909 4.16714 1.63989C4.0887 1.28069 3.8951 0.950743 3.61081 0.691774C3.32652 0.432805 2.96431 0.256445 2.56999 0.184995C2.17567 0.113546 1.76694 0.150216 1.3955 0.290369C1.02406 0.430522 0.706578 0.667863 0.483212 0.972378C0.259847 1.27689 0.140626 1.63491 0.140626 2.00114C0.140626 2.49225 0.354794 2.96325 0.736016 3.31051C1.11724 3.65778 1.63428 3.85287 2.17341 3.85287ZM2.17341 13.1115C1.77136 13.1115 1.37835 13.2201 1.04406 13.4236C0.709767 13.6271 0.449219 13.9163 0.295363 14.2546C0.141506 14.593 0.10125 14.9653 0.179685 15.3245C0.258121 15.6837 0.451725 16.0136 0.736016 16.2726C1.02031 16.5316 1.38251 16.7079 1.77683 16.7794C2.17116 16.8508 2.57988 16.8142 2.95132 16.674C3.32277 16.5339 3.64025 16.2965 3.86361 15.992C4.08698 15.6875 4.2062 15.3295 4.2062 14.9632C4.2062 14.4721 3.99203 14.0011 3.61081 13.6539C3.22959 13.3066 2.71254 13.1115 2.17341 13.1115ZM2.17341 6.63047C1.77136 6.63047 1.37835 6.73907 1.04406 6.94254C0.709767 7.14601 0.449219 7.43521 0.295363 7.77357C0.141506 8.11193 0.10125 8.48425 0.179685 8.84345C0.258121 9.20265 0.451725 9.5326 0.736016 9.79157C1.02031 10.0505 1.38251 10.2269 1.77683 10.2983C2.17116 10.3698 2.57988 10.3331 2.95132 10.193C3.32277 10.0528 3.64025 9.81548 3.86361 9.51096C4.08698 9.20645 4.2062 8.84843 4.2062 8.4822C4.2062 7.99109 3.99203 7.52009 3.61081 7.17283C3.22959 6.82556 2.71254 6.63047 2.17341 6.63047Z"
                                                                                        fill="#646C78"
                                                                                    />
                                                                                </svg>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="card border-0 mt-4 mt-md-0">
                                                    <div className="card-body p-5">
                                                        <div className="d-flex align-items-center gap-4">
                                                            <div className="position-relative">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={70}
                                                                    height={70}
                                                                    viewBox="0 0 70 70"
                                                                    fill="none"
                                                                >
                                                                    <circle
                                                                        cx={35}
                                                                        cy={35}
                                                                        r={35}
                                                                        fill="#FFB800"
                                                                        fillOpacity="0.07"
                                                                    />
                                                                </svg>
                                                                <img
                                                                    src="admin-images/png/trophy.png"
                                                                    className="trophy"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="px-3">
                                                                <h5 className="display-5 fw-400 text-black mb-0">{badges?.total_points ? badges?.total_points : 0}</h5>
                                                                <p className="text-muted-light fs-7 fw-400 mb-0">Points</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="card border-0 mt-4">
                                                    <div className="px-3 pt-4 d-flex justify-content-between align-items-center">
                                                        <p className="fs-18 fw-600 mb-0">Leaderboard</p>
                                                    </div>
                                                    <div className="card-body p-3">
                                                        <div className="row align-items-center mb-4">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/avatar1.png"
                                                                        className="avatar"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Annette Watson
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">9.3</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-4">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/avatar2.png"
                                                                        className="avatar"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">You</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.9</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-4">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/avatar3.png"
                                                                        className="avatar"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Ralph Richards
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.7</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-4">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/avatar4.png"
                                                                        className="avatar"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Bernard Murphy
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-4">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/avatar5.png"
                                                                        className="avatar"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Arlene Robertson
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-4">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/avatar6.png"
                                                                        className="avatar"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">Jane Lane</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">8.1</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-4">
                                                            <div className="col-8">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="admin-images/png/avatar7.png"
                                                                        className="avatar"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-muted-light fs-7 fw-500 mb-0">
                                                                        Pat mckinney
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end">
                                                                    <p className="fs-7 fw-500 text-black mb-0">7.9</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-12 col-md-6 col-lg-8">
                                                <div className="section-title col-md-12 col-lg-12 m-auto">
                                                    <span className="d-block text-start">Optional Courses</span>
                                                    <h2 className="text-start text-black display-6 sm-mb-3">
                                                        Our Top Picks for You
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-md-end justify-content-between align-items-center mb-4 mb-md-0">
                                                <Link
                                                    to="/courses-explore"
                                                    className="btn-explore d-flex align-items-center gap-3 rounded-2 docs-creator"
                                                >
                                                    Explore More
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={22}
                                                        height={14}
                                                        viewBox="0 0 22 14"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M1 6.99854H21L16 1"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M21 6.99854L16 13"
                                                            stroke="white"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                        <OwlCarousel className="owl-carousel admin-featured-courses-carousel featured-courses-carousel-1 owl-theme" loop margin={10} nav={false} responsive={featuredresponsiveOptions}>
                                        {
                                            (carouselItems.length < 0) ? (
                                                // Render 3 CourseCardSkeleton components when loading
                                                <>
                                                <div className="col"><CourseCardSkeleton /></div>
                                                <div className="col"><CourseCardSkeleton /></div>
                                                <div className="col"><CourseCardSkeleton /></div>
                                                </>
                                            ) : (
                                                // Render the last three course items when not loading
                                                listCourse?.results?.slice(-3).map((data, index) => (
                                                <div key={index} className="col">
                                                    {data.is_accessible ? (
                                                        <CourseCardContent url={`/course-details/${data.course_id}`} data={data} />
                                                    ) : (
                                                    <CourseCardContent url={null} data={data} />
                                                    )}
                                                </div>
                                                ))
                                            )
                                            }
                                            {/* <div className="item">
                                                <Link to="">
                                                    <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                                                        <img
                                                            src="./featured-courses/featured01.jpg"
                                                            className="featured-courses-img"
                                                            alt=""
                                                        />
                                                        <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                                                            Bronze
                                                        </span>
                                                        <div className="card-body">
                                                            <span className="badge badge-light rounded-2 fw-400 fs-6">
                                                                Marketing and Sales
                                                            </span>
                                                            <h5>A Guide to Fraud Prevention</h5>
                                                            <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={30}
                                                                    height={30}
                                                                    viewBox="0 0 30 30"
                                                                    fill="none"
                                                                >
                                                                    <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                                                                </svg>
                                                                Professor Sophia Lee
                                                            </p>
                                                            <div className="row justify-content-between align-items-center mt-4 mb-4 mb-md-1">
                                                                <p className="fw-500 text-black mb-4 mb-md-0 col-12 col-md-8 col-lg-6 col-xl-8">
                                                                    Beginner Boost
                                                                </p>
                                                                <div className="col-12 col-md-4 col-lg-6  col-xl-4 d-flex justify-content-between align-items-center">
                                                                    <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                                                        <li className="active" />
                                                                        <li className="active" />
                                                                        <li className="active" />
                                                                        <li />
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-between align-items-center">
                                                                <p className="fw-500 text-black col-12 col-md-8 col-lg-6 col-xl-8 mb-3 mb-md-0">
                                                                    Starter Pack
                                                                </p>
                                                                <div className="col-12 col-md-4 col-lg-6 col-xl-4 d-flex align-items-center gap-2">
                                                                    <img
                                                                        src="./featured-courses/piggy-bank.jpg"
                                                                        className="wh-25"
                                                                        alt=""
                                                                    />
                                                                    <p className="mb-0 text-black fw-600">Explore Free</p>
                                                                </div>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn-primary d-flex align-items-center gap-3 rounded-2 mt-4"
                                                            >
                                                                Enroll Today
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={22}
                                                                    height={14}
                                                                    viewBox="0 0 22 14"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M1 6.99854H21L16 1"
                                                                        stroke="white"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M21 6.99854L16 13"
                                                                        stroke="white"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div> */}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />

            {/* All Badges Modal start */}

            {badges?.data?.length >= 1 &&
                <Modal className='all-badges-modal-main' show={show} onHide={handleClose} size="xl">
                    <Modal.Header className='align-items-start badge-modal-header border-0 p-4 pb-0' closeButton>
                        <div>
                            <Modal.Title>All Badges</Modal.Title>
                            <p>Get badges for completing courses, chapters, and specific challenges. </p>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='p-4 badge-list-mail '>
                        <div className="row g-4 pb-4 pb-md-5">
                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "bronze").length >= 1 &&

                                <div className='d-lg-flex gap-3 align-items-center mb-4'>
                                    <p className='mb-3 mb-lg-0 course-badges'>Bronze Course Badges</p>
                                    {badges?.is_badges_completed?.bronze ?
                                        <button type="button" className='badges-unlocked-btn border-0 p-3 rounded-3 d-flex gap-2 align-items-center'>All badges are unlocked <img src="png/check.png" alt="" /></button>
                                        :
                                        <button type="button" onClick={handelRedirect} className='unlock-more-btn p-3 rounded-3 d-flex gap-2 align-items-center bg-transparent'>Unlock more <img src="png/arrow.png" alt="" /></button>
                                    }
                                </div>
                            }

                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "bronze")
                                .map((badge, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3 border-right-badge mb-5 mb-md-5">
                                        <div className='badge-image'>
                                            {/* Use the badge's image property if available, otherwise fallback to a default */}
                                            <img src={getImageBaseUrl() + badge.image} alt={badge.name} />
                                        </div>
                                        <p className='badge-title'>{badge.name}</p>
                                        {/* Replace the hardcoded date and count with dynamic data if available */}
                                        <p className='badge-subtitle'>Earned {badge.created_at}
                                            {/* <br />10/10 */}

                                        </p>
                                    </div>
                                ))
                            }

                            {/* <div className="col-6 col-md-3 border-right-badge border-sm-right-0 mb-0 mb-md-5">
                            <div className='badge-image'>
                                <img src="png/badge5.png" alt="" />
                            </div>
                            <p className='badge-title'>Bronze Scholar</p>
                            <p className='badge-subtitle'>Earned Jun 17 2024 <br />10/10</p>
                        </div>
                        <div className="col-6 col-md-3 border-right-badge mb-0 mb-md-5">
                            <div className='badge-image'>
                                <img src="png/badge3.png" alt="" />
                            </div>
                            <p className='badge-title'>Bronze Expert</p>
                            <p className='badge-subtitle'>Earned Jun 17 2024 <br />20/20</p>
                        </div>
                        <div className="col-6 col-md-3 mb-0 mb-md-5">
                            <div className='badge-image'>
                                <img src="png/badge2.png" alt="" />
                            </div>
                            <p className='badge-title'>Bronze Virtuoso</p>
                            <p className='badge-subtitle'>Earned Jun 17 2024 <br />50/50</p>
                        </div>
                        <div className="col-6 col-md-3 border-right-badge">
                            <div className='badge-image'>
                                <img src="png/badge1.png" alt="" />
                            </div>
                            <p className='badge-title'>Bronze Maestro</p>
                            <p className='badge-subtitle'>100/100</p>
                        </div>
                        <div className="col-6 col-md-3 border-right-badge border-sm-right-0">
                            <div className='badge-image'>
                                <img src="png/badge9.png" alt="" />
                            </div>
                            <p className='badge-title'>Bronze Legend</p>z
                            <p className='badge-subtitle'>200/200</p>
                        </div>
                        <div className="col-6 col-md-3 border-right-badge">
                            <div className='badge-image'>
                                <img src="png/badge10.png" alt="" />
                            </div>
                            <p className='badge-title'>Bronze Phenom</p>
                            <p className='badge-subtitle'>500/500</p>
                        </div> */}
                        </div>

                        <div className="row g-4">

                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "silver").length >= 1 &&


                                <div className='d-lg-flex gap-3 align-items-center mb-4'>
                                    <p className='mb-3 mb-lg-0 course-badges'>Silver Course Badges</p>
                                    {badges?.is_badges_completed.silver ?
                                        <button type="button" className='badges-unlocked-btn border-0 p-3 rounded-3 d-flex gap-2 align-items-center'>All badges are unlocked <img src="png/check.png" alt="" /></button>
                                        :
                                        <button type="button" onClick={handelRedirect} className='unlock-more-btn p-3 rounded-3 d-flex gap-2 align-items-center bg-transparent'>Unlock more <img src="png/arrow.png" alt="" /></button>
                                    }
                                </div>
                            }

                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "silver")
                                .map((badge, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3 border-right-badge mb-5 mb-md-5">
                                        <div className='badge-image'>
                                            {/* Use the badge's image property if available, otherwise fallback to a default */}
                                            <img src={getImageBaseUrl() + badge.image} alt={badge.name} />
                                        </div>
                                        <p className='badge-title'>{badge.name}</p>
                                        {/* Replace the hardcoded date and count with dynamic data if available */}
                                        <p className='badge-subtitle'>Earned {badge.created_at}
                                            {/* <br />10/10 */}

                                        </p>
                                    </div>
                                ))
                            }

                        </div>

                        <div className="row g-4">
                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "performance").length >= 1 &&

                                <div className='d-lg-flex gap-3 align-items-center mb-4'>
                                    <p className='mb-3 mb-lg-0 course-badges'>Performance Badges</p>
                                    {badges?.is_badges_completed?.performance ?
                                        <button type="button" className='badges-unlocked-btn border-0 p-3 rounded-3 d-flex gap-2 align-items-center'>All badges are unlocked <img src="png/check.png" alt="" /></button>
                                        :
                                        <button type="button" onClick={handelRedirect} className='unlock-more-btn p-3 rounded-3 d-flex gap-2 align-items-center bg-transparent'>Unlock more <img src="png/arrow.png" alt="" /></button>
                                    }
                                </div>

                            }

                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "performance")
                                .map((badge, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3 border-right-badge mb-5 mb-md-5">
                                        <div className='badge-image'>
                                            {/* Use the badge's image property if available, otherwise fallback to a default */}
                                            <img src={getImageBaseUrl() + badge.image} alt={badge.name} />
                                        </div>
                                        <p className='badge-title'>{badge.name}</p>
                                        {/* Replace the hardcoded date and count with dynamic data if available */}
                                        <p className='badge-subtitle'>Earned {badge.created_at}
                                            {/* <br />10/10 */}
                                        </p>
                                    </div>
                                ))
                            }

                            {/* <div className='d-flex gap-3 align-items-center mb-4'>
                                <p className='mb-0 course-badges'>Performance Badges</p>
                                <button type="button" className='unlock-more-btn p-3 rounded-3 d-flex gap-2 align-items-center bg-transparent'>Unlock more <img src="png/arrow.png" alt="" /></button>
                            </div> */}
                            {/* badge image 1 */}
                            {/* <div className="col-6 col-md-3 border-right-badge mb-0 mb-md-5">
                                <div className='badge-image'>
                                    <img src="png/performance-badge1.png" alt="" />
                                </div>
                                <p className='badge-title'>Top Achiever</p>
                                <p className='badge-subtitle'>Earned Jun 17 2024</p>
                            </div> */}
                            {/* badge image 2 */}
                            {/* <div className="col-6 col-md-3 border-right-badge border-sm-right-0 mb-0 mb-md-5">
                                <div className='badge-image'>
                                    <img src="png/performance-badge2.png" alt="" />
                                </div>
                                <p className='badge-title'>Consistent Performer</p>
                                <p className='badge-subtitle'>Score 90% or above on 10 quizzes</p>
                            </div> */}
                            {/* badge image 3 */}
                            {/* <div className="col-6 col-md-3 border-right-badge mb-0 mb-md-5">
                                <div className='badge-image'>
                                    <img src="png/performance-badge3.png" alt="" />
                                </div>
                                <p className='badge-title'>Master of Knowledge</p>
                                <p className='badge-subtitle'>Score 100% on 30 quizzes</p>
                            </div> */}
                            {/* badge image 4 */}
                            {/* <div className="col-6 col-md-3 mb-0 mb-md-5">
                                <div className='badge-image'>
                                    <img src="png/performance-badge4.png" alt="" />
                                </div>
                                <p className='badge-title'>Big Master of Knowledge</p>
                                <p className='badge-subtitle'>Score 100% on 70 quizzes</p>
                            </div> */}
                            {/* badge image 5 */}
                            {/* <div className="col-6 col-md-3 border-right-badge">
                                <div className='badge-image'>
                                    <img src="png/badge8.png" alt="" />
                                </div>
                                <p className='badge-title'>Unstoppable</p>
                                <p className='badge-subtitle'>Earned Jun 17 2024</p>
                            </div> */}
                        </div>

                        <div className="row g-4">
                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "others").length >= 1 &&

                                <div className='d-lg-flex gap-3 align-items-center mb-4'>
                                    <p className='mb-3 mb-lg-0 course-badges'>Other Badges</p>
                                    {badges?.is_badges_completed?.others ?
                                        <button type="button" className='badges-unlocked-btn border-0 p-3 rounded-3 d-flex gap-2 align-items-center'>All badges are unlocked <img src="png/check.png" alt="" /></button>
                                        :
                                        <button type="button" onClick={handelRedirect} className='unlock-more-btn p-3 rounded-3 d-flex gap-2 align-items-center bg-transparent'>Unlock more <img src="png/arrow.png" alt="" /></button>
                                    }
                                </div>
                            }

                            {badges && badges.data && badges.data
                                .filter(data => data.badge_type === "others")
                                .map((badge, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3 border-right-badge mb-5 mb-md-5">
                                        <div className='badge-image'>
                                            {/* Use the badge's image property if available, otherwise fallback to a default */}
                                            <img src={getImageBaseUrl() + badge.image} alt={badge.name} />
                                        </div>
                                        <p className='badge-title'>{badge.name}</p>
                                        {/* Replace the hardcoded date and count with dynamic data if available */}
                                        <p className='badge-subtitle'>Earned {badge.created_at}
                                            {/* <br />10/10 */}
                                        </p>
                                    </div>
                                ))
                            }

                            {/* <div className='d-flex gap-3 align-items-center mb-4'>
                                <p className='mb-0 course-badges'>Other Badges</p>
                                <button type="button" className='unlock-more-btn p-3 rounded-3 d-flex gap-2 align-items-center bg-transparent'>Unlock more <img src="png/arrow.png" alt="" /></button>
                            </div> */}
                            {/* badge image 1 */}
                            {/* <div className="col-6 col-md-3 border-right-badge mb-0 mb-md-5">
                                <div className='badge-image'>
                                    <img src="png/badge4.png" alt="" />
                                </div>
                                <p className='badge-title'>Quick Thinker</p>
                                <p className='badge-subtitle'>Earned Jun 17 2024</p>
                            </div> */}
                            {/* badge image 2 */}
                            {/* <div className="col-6 col-md-3 border-right-badge border-sm-right-0 mb-0 mb-md-5">
                                <div className='badge-image'>
                                    <img src="png/badge7.png" alt="" />
                                </div>
                                <p className='badge-title'>Question Editor</p>
                                <p className='badge-subtitle'>Earned Jun 17 2024</p>
                            </div> */}

                        </div>
                    </Modal.Body>
                </Modal>
            }


            {/* All Badges Modal end */}
        </>
    )
}

export default BusinessFamily