import React, { useRef, useState, useEffect, useContext } from 'react'
import Nav from 'react-bootstrap/Nav';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../layout/Header';
import KnowledgeHubCategories from '../components/KnowledgeHubCategories';
import Footer from '../layout/Footer';
import StudentTestimonials from '../components/StudentTestimonials';
import Faq from '../components/Faq';
import OnlyMe from '../components/OnlyMe';
import MyTeam from '../components/MyTeam';
import { Link } from 'react-router-dom';
import { CourseContext } from '../context/CourseContext';
import CourseCard from '../components/CourseCard';
import AccessCourse from '../components/AccessCourse';
import { CarouselItemSkeleton, CourseCardSkeleton } from '../components/SkeletonLayouts';
import { Row } from 'react-bootstrap';
import { capitalizeWords } from '../helper/constants';
import api from '../helper/api';
import { getServerURL } from '../helper/envConfig';



const Index = () => {

  const {getCategorysIndex,categoriesIndex, setCategoriesIndex, loder, token, setToken, user, setUser, is_search, setSearch, search, searchex, listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses, setLoder } = useContext(CourseContext);

  const responsiveOptions = {
    0: {
      items: 1,
      margin: 0,
    },

    768: {
      items: 2
    },

    1024: {
      items: 2
    },

    1440: {
      items: 2
    },

    1660: {
      items: 4
    }
  };

  const responsiveOptions2 = {
    0: {
      items: 1,
      margin: 0,
    },

    768: {
      items: 2
    },

    1024: {
      items: 2
    },

    1440: {
      items: 2
    },

    1660: {
      items: 4
    }
  };

  const serverURL = getServerURL();
  const [activeTab, setActiveTab] = useState('onlyme');
  // const [categories, setCategoriesIndex] = useState([]);


  const handleNavItemClick = (eventKey) => {
    setActiveTab(eventKey);
  };

  const [carouselItems, setCarouselItems] = useState([]);



  useEffect(() => {
    getCategorysIndex();
  }, []);

  useEffect(() => {
    getCourses();
  }, [currentPage, pageSize, is_search]);

  useEffect(() => {
    if (listCourse?.results) {
      setCarouselItems(listCourse?.results);
    }
  }, [listCourse?.results]);


  // Function to return the course card content
  function CourseCardContent({ data , url }) {
    return (
      // <Link to={`/course-details/${data.course_id}`} >
      <div className="card featured-card overflow-hidden shadow border-0 position-relative">
        <img
          src="./featured-courses/featured01.jpg"
          className="featured-img"
          alt=""
        />
        <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
          {data.type_name}
        </span>
        <div className="card-body">
          <span className="badge badge-light rounded-2 fw-400 fs-6">
            {capitalizeWords(data?.tags_list[0]?.name)}
          </span>
          <h5>{data.name}</h5>
          {/* <p className="d-flex align-items-center gap-2 fw-400 text-dark mt-3 mb-4">
                          <div className="instructor overflow-hidden">
                            <img src="./featured-courses/instructor.png" class="instructor" alt="" />
                          </div>
                          Professor Sophia Lee
                        </p> */}
          {/* <p className="text-muted fw-400 fs-7 pt-2">
                            Welcome to "Strategies for Selling Success"! In this
                            comprehensive course, you'll embark on a transformative
                            journey that will elevate your sales prowess to new heights.
                          </p> */}
          {/* <div className="row justify-content-between align-items-center mt-4 mb-1">
            <p className="fw-500 text-black mb-0 col-6 fs-7">
              Beginner Boost
            </p>
            <div className="col-6 d-flex justify-content-between align-items-center">
              <ul className="rectangle d-flex align-items-center gap-2 gap-md-3 flex-wrap mb-0 p-0">
                <li className="active" />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div> */}
          {/* <div className="row justify-content-between align-items-center">
                          <p className="fw-500 text-black col-6 fs-7">Starter Pack</p>
                          <div className="col-6 d-flex align-items-center gap-2">
                            <img
                              src="./featured-courses/piggy-bank.jpg"
                              className="wh-25"
                              alt=""
                            />
                            <p className="mb-0 text-black fw-500 fs-7">
                              Explore Free
                            </p>
                          </div>
                        </div> */}
          {/* <div className="d-flex align-items-center gap-4">
            <span className="badge badge-light rounded-2 fs-5 fw-600 px-4">
              1K
            </span>
            <div className="d-flex align-items-center gap-2">
              <img
                src="./featured-courses/calibration.jpg"
                className="wh-25"
                alt=""
              />
              <p className="mb-0 text-black fw-400 fs-7">
                Graduated with Glory
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center gap-4 mt-3">
            <span className="badge badge-light rounded-2 fs-5 fw-600">
              213
            </span>
            <div className="d-flex align-items-center gap-2">
              <img
                src="/featured-courses/desktop.jpg"
                className="wh-25"
                alt=""
              />
              <p className="mb-0 text-black fw-400 fs-7">
                Signed Up for Success
              </p>
            </div>
          </div> */}

          <AccessCourse url={url} is_accessible={data?.is_accessible} />

        </div>
      </div>
      // </Link>
    );
  }

  return (
    <div>
      <Header />
      <main>
        <section className="position-sm-relative">
          <div className="home-main">
            <div className="container">
              <div className="home-text-box">
                <h1 className="col-md-9 col-lg-5 col-xl-6 text-center m-auto text-white fw-400">
                  Life long learning with easy courses for free
                </h1>
                <div className="d-flex justify-content-center align-items-center">

                  <Link
                    to="/courses"
                    className="btn btn-warning mt-4 free-trial"
                  >
                    <img src="./svg/play-circle.svg" alt="" />
                    Start Your Free Trial Today
                  </Link>

                  {/* {token ?
                    <Link
                      to="/courses-explore"
                      className="btn btn-warning mt-4 free-trial"
                    >
                      <img src="./svg/play-circle.svg" alt="" />
                      Start Your Free Trial Today
                    </Link>
                    :
                    <Link
                      to="/sign-in"
                      className="btn btn-warning mt-4 free-trial"
                    >
                      <img src="./svg/play-circle.svg" alt="" />
                      Start Your Free Trial Today
                    </Link>
                  } */}
                </div>
              </div>
            </div>
            <img
              src="./png/vector-1.png"
              alt=""
              className="vector-1 d-none d-lg-block"
            />
            <img
              src="./gif/ezgif.com-rotate.gif"
              alt=""
              className="working-girl d-none d-lg-block"
            />
            <img
              src="./png/vector-2.png"
              alt=""
              className="vector-2 d-none d-lg-block"
            />
            <img
              src="./svg/juicy-online-presentation.svg"
              alt=""
              className="online-presentation d-none d-lg-block"
            />
          </div>

          {
            loder && (carouselItems.length <= 0) &&
            <OwlCarousel className='owl-theme home-slider Skeleton-slider' loop nav={false} center={true} items={2} margin={-30} responsive={responsiveOptions2}>
              <CarouselItemSkeleton />
            </OwlCarousel>
          }

          {
            (carouselItems.length >= 0) &&
            <OwlCarousel className='owl-theme home-slider' loop nav={false} center={true} items={2} margin={-30} responsive={responsiveOptions}
              autoplay
              autoplayTimeout={5000}
            >
              {listCourse?.results && listCourse?.results?.map((data, index) => (
                <div key={index} className="item">
                  <Link to={`/course-details/${data.course_id}`} >
                    <div className="card rounded-1 border-0 overflow-hidden shadow-sm">
                      <img
                        src="./featured-courses/featured01.jpg"
                        alt=""
                        className="
                          rounded-0"
                      />
                      <div className="card-body p-4">
                        <span className="badge">
                          <p className='one-line'>
                          {capitalizeWords(data?.tags_list[0]?.name)}
                          </p>
                        </span>
                        <div className="row justify-content-between align-items-center mt-3">
                          <div className="col-10" style={{ maxHeight: '4.5em', overflow: 'hidden' }}>
                            <h5 className="fw-400 text-black mb-0" style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} title={data.name}>
                              {data.name}
                            </h5>
                          </div>
                          <div className="col-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={21}
                              height={21}
                              viewBox="0 0 21 21"
                              fill="none"
                            >
                              <path
                                d="M10.5625 0.75L8.8 2.5125L15.775 9.5H0.5625V12H15.775L8.8 18.9875L10.5625 20.75L20.5625 10.75L10.5625 0.75Z"
                                fill="#000C2A"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

              ))
              }
            </OwlCarousel>
          }

        </section>

        <KnowledgeHubCategories categories={categoriesIndex} />
        <section>
          <div className="featured-main">
            <div className="container">
              <div className="section-title col-md-8 col-lg-6 m-auto">
                <span className="d-block">Featured Courses</span>
                <h2> Our Top Picks for You</h2>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4 g-md-3 g-lg-3">
                {
                  (!carouselItems || carouselItems.length === 0) ? (
                    // Render 3 CourseCardSkeleton components when loading
                    <>
                      <div className="col"><CourseCardSkeleton /></div>
                      <div className="col"><CourseCardSkeleton /></div>
                      <div className="col"><CourseCardSkeleton /></div>
                    </>
                  ) : (
                    // Render the last three course items when not loading
                    listCourse?.results?.slice(-3).map((data, index) => (
                      <div key={index} className="col">
                        {data.is_accessible ? (
                            <CourseCardContent url={`/course-details/${data.course_id}`} data={data} />
                        ) : (
                          <CourseCardContent url={null} data={data} />
                        )}
                      </div>
                    ))
                  )
                }
              </div>
              <div className="d-flex justify-content-center mt-3 mt-md-4 mt-xl-5">
                <Link
                  to="/courses-explore"
                  className="btn-explore d-flex align-items-center gap-3 rounded-2 mt-4 fw-600"
                >
                  Explore More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={14}
                    viewBox="0 0 22 14"
                    fill="none"
                  >
                    <path
                      d="M1 6.99854H21L16 1"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21 6.99854L16 13"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>{" "}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="unique-edge-main position-relative">
            <div className="container">
              <div className="section-title">
                <span className="d-block fs-18">Unique Edge</span>
                <h2 className="mb-4 col-md-12 col-lg-6 m-auto mt-3">
                  Unlock Your Learning Potential!
                </h2>
                <p className="text-center mb-0 col-md-12 col-lg-10 m-auto fs-19 text-muted">
                  In our commitment to providing an exceptional eLearning experience,
                  we take pride in the unique key features that set us apart from the
                  rest. With a focus on creating an environment where learning becomes
                  an exciting journey, we've carefully crafted each feature to cater
                  to your needs and empower you to reach new heights in your knowledge
                  and skills. Here's what makes our platform truly special:
                </p>
              </div>
              <div className="row justify-content-between align-items-center g-3 gx-md-4 mt-1 mt-md-2 mt-lg-0 z-1">
                <div className="col-lg-6">
                  <div className="card border-0 shadow-none bg-transparent h-100">
                    <img
                      src="/unique-edge/learning1.png"
                      className="w-100"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card border-0 shadow-sm h-100">
                    <div className="card-body text-start p-5">
                      <h2 className="text-black fw-400 mb-4">
                        Fun and Effective Learning
                      </h2>
                      <p className="fw-400 fs-17 text-muted">
                        Engage in a delightful learning experience! Our courses are
                        designed to be both fun and effective, combining interactive
                        elements and gamified learning techniques to keep you
                        motivated and excited throughout your educational journey. Say
                        goodbye to mundane lessons and embrace a dynamic way to gain
                        knowledge. Explore captivating quizzes, interactive exercises,
                        and engaging challenges that make learning an enjoyable
                        adventure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center g-3 gx-md-4 mt-1 mt-md-2 mt-lg-0 z-1">
                <div className="col-lg-6 order-lg-2">
                  <div className="card border-0 shadow-none bg-transparent h-100">
                    <img
                      src="/unique-edge/science.png"
                      className="w-100"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 order-lg-1">
                  <div className="card border-0 shadow-sm h-100">
                    <div className="card-body text-start p-5">
                      <h2 className="text-black fw-400 mb-4">Backed by Science</h2>
                      <p className="fw-400 fs-17 text-muted">
                        Trust in the power of science! Our courses are built on a
                        foundation of research-backed teaching methods that ensure
                        optimal knowledge retention and skill development. From
                        cognitive learning strategies to proven pedagogical
                        approaches, you can rest assured that every aspect of our
                        content is designed to maximize your learning potential.
                        Experience the effectiveness of evidence-based practices
                        tailored to enhance your understanding and boost your
                        confidence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center g-3 gx-md-4 mt-1 mt-md-2 mt-lg-0 z-1">
                <div className="col-lg-6">
                  <div className="card border-0 shadow-none bg-transparent h-100">
                    <img
                      src="/unique-edge/learning2.png"
                      className="w-100"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card border-0 shadow-sm h-100">
                    <div className="card-body text-start p-5">
                      <h2 className="text-black fw-400 mb-4">
                        Personalized Learning
                      </h2>
                      <p className="fw-400 fs-17 text-muted">
                        Tailored to your needs! We understand that every learner is
                        unique, and that's why our platform offers personalized
                        learning paths that adapt to your pace, preferences, and skill
                        level. Through intelligent algorithms and insightful
                        analytics, we curate a learning experience that aligns
                        perfectly with your goals and helps you achieve success on
                        your terms. Discover customized content, adaptive assessments,
                        and recommendations that foster an environment conducive to
                        your growth
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center g-0 g-md-4 mt-1 mt-md-2 mt-lg-0 z-1">
                <div className="col-lg-6 order-lg-2">
                  <div className="card border-0 shadow-none bg-transparent h-100">
                    <img
                      src="/unique-edge/motivated.png"
                      className="w-100"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 order-lg-1">
                  <div className="card border-0 shadow-sm h-100">
                    <div className="card-body text-start p-5">
                      <h2 className="text-black fw-400 mb-4">Stay Motivated</h2>
                      <p className="fw-400 fs-17 text-muted">
                        We understand that staying motivated throughout your learning
                        journey is essential for achieving your goals. That's why our
                        platform is equipped with motivational tools and resources to
                        keep you inspired and driven every step of the way. Experience
                        a supportive learning environment where you can set
                        milestones, track your progress, and celebrate your
                        achievements. Engage with a vibrant community of like-minded
                        learners, exchange ideas, and draw inspiration from others.
                        With regular encouragement, positive reinforcements, and
                        personalized feedback from instructors, you'll stay motivated
                        and focused on your path to success. Together, let's turn your
                        aspirations into reality and make learning a fulfilling and
                        uplifting experience!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/unique-edge/yellow-vector.png"
              className="yellow-vector"
              alt=""
            />
            <img
              src="/unique-edge/pink-vector.png"
              className="pink-vector"
              alt=""
            />
            <img
              src="/unique-edge/light-green-vector.png"

              className="light-grenn-vector"
              alt=""
            />
          </div>
        </section>
        <section>
          <div className="pricing-main position-relative">
            <div className="container">
              <div className="section-title col-md-12 col-lg-7 m-auto">
                <span className="d-block">Pricing</span>
                <h2>Affordable Learning, Priceless Knowledge!</h2>
              </div>
              <div className="row">
                <div className="col-12">
                  <Nav variant="pills" className="pricing-tabs d-flex justify-content-center gap-3 flex-nowrap border-0 gap-3 flex-nowrap w-100 bg-transparent" activeKey={activeTab} onSelect={handleNavItemClick}>
                    <Nav.Item>
                      <Nav.Link eventKey="onlyme">Only For Me</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="myteam">For My Team</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="card border-0">
                    <div className="card-body px-3 px-md-5">
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home-tab-pane"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                          tabIndex={0}
                        >
                        </div>
                        {activeTab === 'onlyme' && <OnlyMe />}
                        {activeTab === 'myteam' && <MyTeam />}
                        <div
                          className="tab-pane fade"
                          id="profile-tab-pane"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                          tabIndex={0}
                        >
                          <div className="table-responsive pricing-table">
                            <table className="table align-middle table-sm">
                              <thead>
                                <tr className="border-bottom-0 border-white">
                                  <th />
                                  <th>Stater Pack</th>
                                  <th>Pro Mastery</th>
                                  <th>Family Fusion</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <p className="fw-600">Courses</p>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="fw-600">
                                      LinkedIn Achievement Badge
                                    </p>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="fw-600">
                                      Leaderboard and Analytics
                                    </p>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_368"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_368)">
                                        <path
                                          d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                          fill="#FFBACC"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="fw-600">
                                      Allow access to multiple users
                                    </p>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_368"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_368)">
                                        <path
                                          d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                          fill="#FFBACC"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_368"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_368)">
                                        <path
                                          d="M10.8333 31.6668L8.5 29.3335L17.8333 20.0002L8.5 10.6668L10.8333 8.3335L20.1667 17.6668L29.5 8.3335L31.8333 10.6668L22.5 20.0002L31.8333 29.3335L29.5 31.6668L20.1667 22.3335L10.8333 31.6668Z"
                                          fill="#FFBACC"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={41}
                                      height={40}
                                      viewBox="0 0 41 40"
                                      fill="none"
                                    >
                                      <mask
                                        id="mask0_257_338"
                                        style={{ maskType: "alpha" }}
                                        maskUnits="userSpaceOnUse"
                                        x={0}
                                        y={0}
                                        width={41}
                                        height={40}
                                      >
                                        <rect
                                          x="0.166748"
                                          width={40}
                                          height={40}
                                          fill="#D9D9D9"
                                        />
                                      </mask>
                                      <g mask="url(#mask0_257_338)">
                                        <path
                                          d="M16.0835 30.0002L6.5835 20.5002L8.9585 18.1252L16.0835 25.2502L31.3752 9.9585L33.7502 12.3335L16.0835 30.0002Z"
                                          fill="#D2E23D"
                                        />
                                      </g>
                                    </svg>
                                  </td>
                                </tr>
                                <tr className="border-bottom-0 border-white">
                                  <td />
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      <button
                                        type="button"
                                        className="btn-primary rounded-2 mx-2"
                                      >
                                        Enroll Today
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      <button
                                        type="button"
                                        className="btn-primary rounded-2 mx-2"
                                      >
                                        3 €‎ / Month
                                      </button>
                                    </div>
                                  </td>
                                  <td className="d-flex justify-content-center">
                                    <div className="d-flex justify-content-center">
                                      <button
                                        type="button"
                                        className="btn-primary rounded-2 mx-2"
                                      >
                                        6 €‎ / Month
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <StudentTestimonials />
        <Faq />
        <section>
          <div className="learning-adventure-main">
            <div className="container">
              <div>
                <p className="text-center text-black fw-400">
                  Join the Learning Adventure
                </p>
                <h2 className="col-md-12 col-lg-8 m-auto text-center display-5 fw-400 text-black mb-4">
                  Creating an account is quick, easy, and completely free!
                </h2>
                <p className="col-md-12 col-lg-9 m-auto text-center mt-4 text-muted">
                  Don't miss out on the opportunity to dive into our diverse array of
                  courses, engage with expert instructors, and connect with a vibrant
                  community of learners. Whether you're a curious beginner or a seasoned
                  learner, our platform offers something for everyone.
                </p>
                <div className="d-md-flex justify-content-center m-auto mt-5">
                  <div className="subscribe-box d-md-flex justify-content-center">
                    <input
                      type="text"
                      className="col-12 col-md-6 form-control subscribe rounded-start-2 rounded-end-0 border-0 h-100 ps-4 w-100 bg-white"
                      placeholder="Type Your Email Address Here"
                      aria-label="First name"
                    />
                    <button
                      type="button"
                      className="col-12 col-md-6 btn-explore fs-6 text-center gap-3 rounded-start-0 rounded-end-2 mt-4 mt-md-0"
                    >
                      Enroll Today
                      <span className="ps-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <mask
                            id="mask0_250_9685"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x={0}
                            y={0}
                            width={24}
                            height={24}
                          >
                            <rect width={24} height={24} fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_250_9685)">
                            <path
                              d="M6.4 18L5 16.6L14.6 7H6V5H18V17H16V8.4L6.4 18Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Index;
