import { useContext, useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from 'react-router-dom';
import BusinessSidebar from '../components/BusinessSidebar';
import { CourseContext } from '../context/CourseContext';
import { getImageBaseUrl } from '../helper/envConfig';
const AdminHeader = () => {
  const [show, setShow] = useState(false);
  const history = useNavigate()

  const { user,  setSearch, setSearchex, search, getCourses, setIs_search } = useContext(CourseContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setSearchex("")
};



const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default action of the enter key in a form
      getCourses(); // Call the function you want to execute on Enter key press
      setIs_search(true)
      history("/courses-explore")
      window.scrollTo(0, 500);
      handleClose()
  }
};


  const getInitials = (username) => {
    let initials = username?.split(' ')?.map(word => word[0])?.join('');
    return initials?.toUpperCase();
  };

  return (
    <>
      <header>
        <div className="header_main header2-main header-shadow">
          <div className="container">
            <nav className="navbar navbar-expand-xl">
              <div className="d-flex justify-content-between align-items-center w-100">
                <Link
                  className="navbar-brand header-logo"
                  to="/"
                >
                  <img src="admin-images/png/logo-2.jpg" alt="" />
                </Link>
                <form className="admin-header-form col-lg-4 col-xl-3 d-none d-lg-block">
                  <div className="d-flex align-content-center gap-3">
                    <div className="form-control sm-none d-flex justify-content-center align-items-center border-0 bg-secondary w-100 rounded-3 overflow-hidden py-0">
                      <Link to="" className="search-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={22}
                          height={22}
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.4004 10.1416C16.4004 13.4602 13.7102 16.1504 10.3916 16.1504C7.07304 16.1504 4.38281 13.4602 4.38281 10.1416C4.38281 6.82304 7.07304 4.13281 10.3916 4.13281C13.7102 4.13281 16.4004 6.82304 16.4004 10.1416ZM16.4278 14.9638C17.485 13.6422 18.1172 11.9657 18.1172 10.1416C18.1172 5.87488 14.6583 2.41602 10.3916 2.41602C6.12488 2.41602 2.66602 5.87488 2.66602 10.1416C2.66602 14.4083 6.12488 17.8672 10.3916 17.8672C12.2157 17.8672 13.8922 17.235 15.2138 16.1778L17.5102 18.4742C17.8454 18.8094 18.3889 18.8094 18.7242 18.4742C19.0594 18.1389 19.0594 17.5954 18.7242 17.2602L16.4278 14.9638Z"
                            fill="#002FA7"
                          />
                        </svg>
                      </Link>
                      <p className="mb-0 ps-2 text-primary"></p>
                      <input
                        className="form-control border-0 rounded-2 bg-secondary px-0"
                        type="text"
                        placeholder="Search the courses"
                        value={search}
                        onChange={handleSearchChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                </form>
                <div className="admin-header-icons">
                  {/* <div className="position-relative d-md-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.49609 8.4248C6.49609 7.95072 6.88041 7.56641 7.35449 7.56641H14.2217C14.6958 7.56641 15.0801 7.95072 15.0801 8.4248C15.0801 8.89889 14.6958 9.2832 14.2217 9.2832H7.35449C6.88041 9.2832 6.49609 8.89889 6.49609 8.4248Z"
                        fill="#002FA7"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.49609 11.8584C6.49609 11.3843 6.88041 11 7.35449 11H10.7881C11.2622 11 11.6465 11.3843 11.6465 11.8584C11.6465 12.3325 11.2622 12.7168 10.7881 12.7168H7.35449C6.88041 12.7168 6.49609 12.3325 6.49609 11.8584Z"
                        fill="#002FA7"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.2564 15.6899C6.56494 15.4328 6.95385 15.292 7.35547 15.292H16.7979C17.2719 15.292 17.6562 14.9077 17.6562 14.4336V5.84961C17.6562 5.37553 17.2719 4.99121 16.7979 4.99121H4.78027C4.30619 4.99121 3.92188 5.37553 3.92188 5.84961V17.6353L6.2564 15.6899ZM7.35547 17.0088H16.7979C18.2201 17.0088 19.373 15.8558 19.373 14.4336V5.84961C19.373 4.42737 18.2201 3.27441 16.7979 3.27441H4.78027C3.35803 3.27441 2.20508 4.42737 2.20508 5.84961V17.6353C2.20508 19.0909 3.90274 19.8861 5.02094 18.9542L7.35547 17.0088Z"
                        fill="#002FA7"
                      />
                    </svg>
                    <div className="error-dots-1" />
                  </div> */}
                  {/* <div className="position-relative d-md-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={21}
                      height={22}
                      viewBox="0 0 21 22"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.00977 14.9451C2.00977 14.6182 2.14256 14.3053 2.37771 14.0782L3.26096 13.2251C3.59719 12.9004 3.78646 12.4526 3.78505 11.9851L3.77693 9.27857C3.76556 5.49182 6.83215 2.41602 10.6189 2.41602C14.3977 2.41602 17.4609 5.47929 17.4609 9.25803L17.4609 12.0057C17.4609 12.461 17.6418 12.8977 17.9638 13.2196L18.8222 14.078C19.0498 14.3057 19.1777 14.6145 19.1777 14.9364C19.1777 15.6069 18.6342 16.1504 17.9638 16.1504H14.0273C14.0273 18.0467 12.4901 19.584 10.5938 19.584C8.69743 19.584 7.16016 18.0467 7.16016 16.1504H3.21502C2.54938 16.1504 2.00977 15.6108 2.00977 14.9451ZM8.87695 16.1504C8.87695 17.0986 9.64559 17.8672 10.5938 17.8672C11.5419 17.8672 12.3105 17.0986 12.3105 16.1504H8.87695ZM15.7441 12.0057C15.7441 12.9163 16.1059 13.7897 16.7498 14.4336L4.48067 14.4336C5.13624 13.7863 5.50461 12.9023 5.50184 11.98L5.49372 9.27341C5.4852 6.43684 7.78232 4.13281 10.6189 4.13281C13.4495 4.13281 15.7441 6.42745 15.7441 9.25803L15.7441 12.0057Z"
                        fill="#002FA7"
                      />
                    </svg>
                    <div className="error-dots-1" />
                  </div> */}
                  <Link to="/edit-profile">
                     { user && user.user_profile ?
                       <div className="customer-image">
                          <img src={getImageBaseUrl() + user.user_profile} alt="" />
                       </div> :
                         <div className="profile-initials">
                             {user?.username?.charAt(0).toUpperCase()}
                         </div>
                     }
                  </Link>
                  <Link
                    to="#"
                    className="text-black fs-3 d-xl-none admin-toggel"
                    onClick={handleShow}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" fill='#000' y="0px" width="25" height="25" viewBox="0 0 50 50">
                      <path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z"></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header className='border-bottom' closeButton>
            <Link className="navbar-brand header-logo" to="/">
              <img src="./logo/white-logo.svg" alt="" />
            </Link>
          </Offcanvas.Header>
          <form className="admin-header-form px-3 py-3 border-bottom">
            <div className="d-flex align-content-center gap-3">
              <div className="form-control d-flex justify-content-center align-items-center border-0 bg-light w-100 rounded-3 overflow-hidden py-2">
                <Link to="" className="search-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.4004 10.1416C16.4004 13.4602 13.7102 16.1504 10.3916 16.1504C7.07304 16.1504 4.38281 13.4602 4.38281 10.1416C4.38281 6.82304 7.07304 4.13281 10.3916 4.13281C13.7102 4.13281 16.4004 6.82304 16.4004 10.1416ZM16.4278 14.9638C17.485 13.6422 18.1172 11.9657 18.1172 10.1416C18.1172 5.87488 14.6583 2.41602 10.3916 2.41602C6.12488 2.41602 2.66602 5.87488 2.66602 10.1416C2.66602 14.4083 6.12488 17.8672 10.3916 17.8672C12.2157 17.8672 13.8922 17.235 15.2138 16.1778L17.5102 18.4742C17.8454 18.8094 18.3889 18.8094 18.7242 18.4742C19.0594 18.1389 19.0594 17.5954 18.7242 17.2602L16.4278 14.9638Z"
                      fill="#002FA7"
                    />
                  </svg>
                </Link>
                <p className="mb-0 ps-2 text-primary">|</p>
                <input
                  className="form-control border-0 rounded-2 bg-light px-0"
                  type="text"
                  placeholder="Search or type"
                />
              </div>
            </div>
          </form>
          <Offcanvas.Body className='sidbar-list-comman'>
            <BusinessSidebar />
          </Offcanvas.Body>
        </Offcanvas>
      </header>

    </>
  )
}

export default AdminHeader
