import React, { useState } from 'react';
import { Nav, Tab, Row, Col, Pagination, Form } from 'react-bootstrap';
import AdminHeader from '../layout/AdminHeader';
import BusinessSidebar from '../components/BusinessSidebar';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import ComplianceScoreChart from '../components/ComplianceScoreChart';
import DoughnutChart2 from '../components/DoughnutChart2';
import { useEffect } from 'react';
import api from '../helper/api';
import { getImageBaseUrl, getServerURL } from '../helper/envConfig';
import DynamicPagination from "../components/DynamicPagination"
import Index from './Index';
import { encryptAndStore } from '../helper/sessionStore';

function BusinessCompliance() {
    const [activeKey, setActiveKey] = useState('1');
    const [data, setData] = useState([]);
    const [dataEployee, setDataEployee] = useState([]);
    const serverURL = getServerURL();
    const [goToPageNumber, setGoToPageNumber] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    // State for tracking window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 768; // Or whatever your mobile breakpoint is

    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const getData = async () => {

        try {
            const response = await api.getWithToken(`${serverURL}users/business-owner/overall-score/`);
            setData(response.data.data)
        } catch (error) {
            console.error(error);

        };

    }

    const getDataEployee = async () => {
        try {
            const response = await api.getWithToken(`${serverURL}users/business-owner/compliance-employee/?page=${currentPage}&page_size=${rowsPerPage}`);
            setDataEployee(response.data)
            setTotalPages(response.data.total_pages)
        } catch (error) {
            console.error(error);
        };

    }

    // Effect for fetching quizzes
    useEffect(() => {
        getData();
        getDataEployee();
    }, [activeKey, currentPage, rowsPerPage]);


    const handleSelectChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page after changing row size
    };

    const handleGoToPageChange = (e) => {
        setGoToPageNumber(e.target.value);
    };

    const handleGoToPage = (e) => {
        e.preventDefault();

        const pageNumber = parseInt(goToPageNumber, 10);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }

    };
    

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="d-md-flex justify-content-between align-items-center gap-3 pt-5 pt-md-0 pb-0 pb-md-4">
                                            <h2 className="text-black fw-600">Compliance Score Dashboard</h2>
                                        </div>
                                        <div className="row g-4 mt-2 mt-md-0">
                                            <div className="col-12 col-lg-12">
                                                <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                                                    <div className="overflow-auto">
                                                        <Nav className="nav-pills mb-2 group-view-list gap-4 col-12 col-md-7 col-lg-5 flex-nowrap" id="pills-tab" role="tablist">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="1" className="fw-600 fs-6 text-black">Overall Score</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="2" className="fw-600 fs-6 text-black">Employees</Nav.Link>
                                                            </Nav.Item>
                                                            {/* <Nav.Item>
                                                                <Nav.Link eventKey="3" className="fw-400 fs-6 text-black">Billing</Nav.Link>
                                                            </Nav.Item> */}
                                                        </Nav>
                                                    </div>
                                                    <Tab.Content className='mb-5 pb-0 pb-md-4'>


                                                        <Tab.Pane eventKey="1">
                                                            <Row className='mt-5 g-4'>
                                                                <Col className="col-12 col-md-6 col-lg-4">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total Employees</h4>
                                                                            <p className='display-6 fw-600 mb-2'>{data?.total_employees}</p>
                                                                            <p className='text-light-2 fw-400'>Employee</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-12 col-md-6 col-lg-4">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total Courses Mandatory</h4>
                                                                            <p className='display-6 fw-600 mb-2'>{data?.total_mandatory_courses}</p>
                                                                            <p className='text-light-2 fw-400'>Courses</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-12 col-md-6 col-lg-4">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <h4 className='fs-20 fw-600 text-light-1 text-capitalize'>Total mandatory courses completed</h4>
                                                                            <p className='display-6 fw-600 mb-2'>{data?.total_completed_courses}</p>
                                                                            <p className='text-light-2 fw-400'>Courses</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='g-4 mt-2'>
                                                            <Col className="col-12 col-md-12 col-lg-6">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <p className='text-light-1 fs-20 fw-600'>Percentage of Completed Courses</p>
                                                                            <h3 className='fw-600'>{data?.completed_percentage} %</h3>
                                                                            <div className='mb-4'>
                                                                                {/* <img src="admin-images/png/arrow-up.png" alt="" /> */}
                                                                                {/* <span className='text-light-4 fw-600 ps-2'>2.1%</span>
                                                                                <span className='text-light-2 fw-400'> vs last month</span> */}
                                                                            </div>
                                                                            <ComplianceScoreChart data={data}/>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            
                                                                <Col className="col-12 col-md-12 col-lg-6">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <p className='text-light-1 fs-20 fw-600'>Overall Compliance Score</p>
                                                                            {/* <h3 className='fw-600'> {data?.completed_percentage}/100</h3> */}
                                                                            <div className='mb-4'>
                                                                                {/* <img src="admin-images/png/arrow-down.png" alt="" /> */}
                                                                                {/* <span className='text-light-3 fw-600 ps-2'>3.5%</span>
                                                                                <span className='text-light-2 fw-400'> vs last month</span> */}
                                                                            </div>
                                                                            <DoughnutChart2 data={data}/>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                {/* <Col className="col-12 col-md-12 col-lg-6">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <p className='text-light-1 fs-20 fw-600'>Overall Compliance Score</p>
                                                                            <h3 className='fw-600'>79/100</h3>
                                                                            <div className='mb-4'>
                                                                                <img src="admin-images/png/arrow-down.png" alt="" />
                                                                                <span className='text-light-3 fw-600 ps-2'>3.5%</span>
                                                                                <span className='text-light-2 fw-400'> vs last month</span>
                                                                            </div>
                                                                            <ComplianceScoreChart />
                                                                        </div>
                                                                    </div>
                                                                </Col> */}
                                                                {/* <Col className="col-12 col-md-12 col-lg-6">
                                                                    <div className="card border-0 rounded-3 h-100">
                                                                        <div className="card-body p-4">
                                                                            <p className='text-light-1 fs-20 fw-600'>Total Points</p>
                                                                            <h5 className='display-3 fw-600 text-center mt-5 naturenova'>79</h5>
                                                                            <p className='text-center fs-20 fw-600 mt-3'>Compliance Score</p>
                                                                            <div className='compliance-score-progress d-flex mt-5'>
                                                                                <p className='very-low' style={{ width: '20%' }}></p>
                                                                                <p className='low' style={{ width: '20%' }}></p>
                                                                                <p className='medium' style={{ width: '20%' }}></p>
                                                                                <p className='high' style={{ width: '20%' }}></p>
                                                                                <p className='very-high' style={{ width: '20%' }}></p>
                                                                            </div>
                                                                            <ul className="px-0 point-list mt-5 d-flex flex-wrap align-items-center gap-4">
                                                                                <li><span className='very-low'></span><b>1-20</b> Very low</li>
                                                                                <li><span className='low'></span><b>21-40</b> Low</li>
                                                                                <li><span className='medium'></span><b>41-60</b> Medium</li>
                                                                                <li><span className='high'></span><b>61-80</b> High</li>
                                                                                <li><span className='very-high'></span><b>81-100</b> Very high</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Col> */}
                                                            </Row>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="2">
                                                            <div className="card border-0 mt-3 mt-md-5">
                                                                <div className="card-body">
                                                                    <div className="table-responsive compliance-score-dashboard">
                                                                        <table className="table align-middle">
                                                                            <thead>
                                                                                <tr className="pb-5">
                                                                                    <th className="table-th-width-350">
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">Name</p>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                                Total Points
                                                                                            </p>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                            Total Courses Done
                                                                                        </p>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="fw-600 fs-6 text-bl mb-0 text-nowrap">
                                                                                            Compliance Score
                                                                                        </p>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {dataEployee?.results?.employees.map((data, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="rounded-start-4">
                                                                                            <div className="row">
                                                                                                <div className="col-12 overflow-hidden">
                                                                                                    <Link to="/employee-profile" onClick={() => encryptAndStore('employee_id', data.employee_id)} className="d-flex align-items-center gap-2">
                                                                                                        <img
                                                                                                            src={data.image ? `${getImageBaseUrl()}${data.image}` : "admin-images/png/toppers1.png"}
                                                                                                            alt=""
                                                                                                            className="toppers"
                                                                                                        />
                                                                                                        <div className="student-details">
                                                                                                            <p className="fw-400 fs-7 text-bl mb-1 table-th-width-167">
                                                                                                                {data.username}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="d-flex align-items-center gap-1">
                                                                                                <p className="loder-text mb-0">{data.total_points}</p>
                                                                                                <img src="admin-images/png/trophy.png" className="" alt="" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="d-flex align-items-center gap-1">
                                                                                                <p className="loder-text mb-0">{data.total_completed_courses}</p>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="rounded-end-4">
                                                                                            <p className="fw-400 fs-6 text-bl mb-0 text-nowrap">
                                                                                                {data.compliance_score ? data.compliance_score : 0 }
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>

                                                    </Tab.Content>
                                                    <div className="col-12 d-flex justify-content-center mt-4">
                                                        {activeKey == 2 &&
                                                            <Pagination className='table-pagination align-items-center'>
                                                                {!isMobile && (
                                                                    <li className="page-item">
                                                                        <a className="page-link w-auto h-auto" role="button" tabIndex="0">
                                                                            Rows per page
                                                                            <Form.Select aria-label="Rows per page" className='select-number' value={rowsPerPage} onChange={handleSelectChange}>
                                                                                <option value="10">10</option>
                                                                                <option value="15">15</option>
                                                                                <option value="20">20</option>
                                                                                <option value="25">25</option>
                                                                            </Form.Select>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                {
                                                                    totalPages >= 2 &&
                                                                    <>
                                                                        <DynamicPagination
                                                                            currentPage={currentPage}
                                                                            totalPages={totalPages}
                                                                            onPageChange={(newPage) => {
                                                                                setCurrentPage(newPage);
                                                                            }}
                                                                        />
                                                                        <div className='ms-0 ms-md-5 d-flex'>
                                                                            <a class="page-link w-auto h-auto border-0 bg-transparent d-none d-lg-block" role="button" tabindex="0" >
                                                                                <span aria-hidden="true" className='text-muted fw-400 fs-7 text-nowrap'>Go to page</span>
                                                                            </a>
                                                                            <input className='form-control border-0 border-bottom rounded-0 w-50 d-none d-lg-block'
                                                                                type="number"
                                                                                min="1" // Minimum value as 1 since page numbers start from 1
                                                                                max={totalPages} // Maximum value as the total number of pages
                                                                                onChange={handleGoToPageChange} value={goToPageNumber}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                        handleGoToPage(e);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <div className='ms-3 cursor-pointer d-none d-lg-block'>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => handleGoToPage(e)}>
                                                                                    <path d="M10.0037 6L8.59375 7.41L13.1737 12L8.59375 16.59L10.0037 18L16.0037 12L10.0037 6Z" fill="#242731" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                }

                                                            </Pagination>

                                                        }

                                                    </div>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default BusinessCompliance
