import { Navigate, useLocation } from 'react-router-dom';
import { decryptFromStore } from './sessionStore';
import { decryptFromStoreLoc } from './localStore';

export function ProtectedQuize({ children }) {
    let location = useLocation();
    // Retrieve and check stored answers
    const storedAnswers = decryptFromStore('quiz') || decryptFromStore('FinalAnswers');
    const hasQuizId = storedAnswers && storedAnswers.quiz_id;

    return hasQuizId ? children : <Navigate to="/" state={{ from: location }} replace />;
}

export function ProtectedQuizeresult({ children }) {
    let location = useLocation();
    // Retrieve and check stored answers
    const storedAnswers = decryptFromStore('FinalAnswers');
    const hasQuizId = storedAnswers && storedAnswers.quiz_id;
    return hasQuizId ? children : <Navigate to="/" state={{ from: location }} replace />;
}


export function ProtectedRole({ children }) {
    let location = useLocation();
    // Retrieve and check stored answers
    const storedAnswers = decryptFromStore('role');
    const hasQuizId = storedAnswers ;
    return hasQuizId ? children : <Navigate to="/sign-up" state={{ from: location }} replace />;
}

export function ProtectedLogin({ children }) {
    let location = useLocation();
    // Retrieve and check stored answers
    const storedAnswers = localStorage.getItem("token")
    const hasQuizId = !storedAnswers 
    return hasQuizId ? children : <Navigate to="/" state={{ from: location }} replace />;
}
