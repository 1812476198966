import React from 'react'
import { Link } from 'react-router-dom';
import AccessCourse from './AccessCourse';
import { capitalizeWords } from '../helper/constants';

const CourseCard = ({ index, course_id, type_name, name, is_accessible, tags_list }) => {


    function CourseCardContent({url}) {

        return (
            <div className="card featured-card overflow-hidden border-0 position-relative accordion-items-shadow">
                <img
                    src="../featured-courses/featured01.jpg"
                    className="featured-courses-img"
                    alt=""
                />
                <span className="badge badge-warning bg-warning position-absolute top-0 start-0">
                    {type_name}
                </span>
                <div className="card-body">
                    <span className="badge badge-light rounded-2 fw-400 fs-6">
                    {capitalizeWords(tags_list)}
                    </span>
                    <h5>{name}</h5>
                    {/* <p className="d-flex align-items-center gap-2 fw-500 text-dark mt-3 mb-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                >
                    <circle cx={15} cy={15} r={15} fill="#D9D9D9" />
                </svg>
                Professor Sophia Lee
            </p> */}
                    <div className="row justify-content-between align-items-center mt-4 mb-1">
                        <p className="fw-500 text-black mb-0 col-7">
                            Beginner Boost
                        </p>
                        <div className="col-5 d-flex justify-content-between align-items-center">
                            <ul className="rectangle d-flex align-items-center gap-2  flex-wrap mb-0 p-0">
                                <li className="active" />
                                <li className="active" />
                                <li className="active" />
                                <li />
                            </ul>
                        </div>
                    </div>
                    {/* <div className="row justify-content-between align-items-center">
                <p className="fw-500 text-black col-6 mb-0">
                    Starter Pack
                </p>
                <div className="col-6 d-flex align-items-center gap-2">
                    <img
                        src="./featured-courses/piggy-bank.jpg"
                        className="wh-25"
                        alt=""
                    />
                    <p className="mb-0 text-black fw-600">Explore Free</p>
                </div>
            </div> */}
                    <AccessCourse url={url} is_accessible={is_accessible} />

                </div>
            </div>
        )

    }

    return (
        <div key={index} className="item">
            {is_accessible ? (
                // Wrap with Link when is_accessible is true
                    <CourseCardContent url={`/course-details/${course_id}`} />
            ) : (
                // Render the content directly without Link when is_accessible is false
                <CourseCardContent url={null} />
            )}
        </div>)
}

export default CourseCard