import React, { useEffect, useState } from 'react';
import BusinessSidebar from '../components/BusinessSidebar'
import AdminHeader from './../layout/AdminHeader';
import Footer from '../layout/Footer';
import SliderHomeSec from './../components/SliderHomeSec';
import AreaChart from './../components/AreaChart';
import BarChart1 from './../components/BarChart1';
import BarChart2 from './../components/BarChart2';
import DoughnutChart from '../components/DoughnutChart';
import TopPerformers from '../components/TopPerformers';
import { Link } from 'react-router-dom';
import { decryptFromStoreLoc } from '../helper/localStore';

const BusinessDashbaordHome = () => {

    const [user, setUser] = useState({});

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        // if(user.role ==)
        setUser(decryptedInfoResult)
    }, []);

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                                <BusinessSidebar />
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <SliderHomeSec />
                                        <div className='d-md-flex justify-content-between align-items-center gap-3 pt-5 pt-md-0 pb-0 pb-md-5'>
                                            <h2 className="text-black fw-600 text-">
                                                Welcome <span className="text-capitalize">{user?.username}</span>,
                                            </h2>
                                            {/* {(user?.role === 9 || user?.role === 12) &&
                                                <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3 mt-4 mt-md-0'>
                                                    <Link to="/bulk-upload" className='border-primary-btn d-flex align-items-center gap-2'>
                                                        Bulk Upload
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.83333 12L12 16.375M12 16.375L16.1667 12M12 16.375V5M7 19H17" stroke="#002FA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </Link>
                                                    <Link to="/assign-course" className='btn-primary2 d-flex align-items-center gap-2'>
                                                        Assign Course
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_160_125)">
                                                                <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_160_125">
                                                                    <rect width="24" height="24" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            } */}

                                        </div>
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-5 pt-5 pt-md-0">
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/trophy2.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Courses taken this year
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">250</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/desktop.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Mandatory Courses for all employees
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">20</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/clock2.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Overdue mandatory courses for all employees
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">3</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card border-0 h-100 rounded-3">
                                                    <div className="card-body p-4 pb-0">
                                                        <div className="icons-crile mb-4">
                                                            <img
                                                                src="./admin-images/png/employee.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <p className="fw-12 fw-400 text-muted mb-4">
                                                            Total Sign Up
                                                        </p>
                                                    </div>
                                                    <div className="card-footer bg-transparent border-0 pt-0 px-4">
                                                        <h2 className="fw-400 text-black">200</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-4 pb-5">
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Monthly Compliance Grade
                                                            </p>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_361_16694"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#002FA7" />
                                                                </mask>
                                                                <g mask="url(#mask0_361_16694)">
                                                                    <path
                                                                        d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                                                                        fill="#000C2A"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <select
                                                            className="form-select w-auto rounded-5 border border-secondary fs-7 fw-400 month-select-drop"
                                                            aria-label="Default select example"
                                                        >
                                                            <option selected="">October 2020</option>
                                                            <option value={1}>October 2022</option>
                                                            <option value={2}>October 2023</option>
                                                            <option value={3}>October 2024</option>
                                                        </select>
                                                    </div>
                                                    <div className="card-body p-4">
                                                        <div className="overflow-auto">
                                                            <div className="barchart">
                                                                <BarChart1 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-header bg-transparent border-secondary d-flex align-items-center py-4 position-relative">
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <p className="text-black fw-600 fs-18 mb-0">
                                                                Monthly GDPR Compliance Grade
                                                            </p>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={24}
                                                                height={24}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <mask
                                                                    id="mask0_361_16694"
                                                                    style={{ maskType: "alpha" }}
                                                                    maskUnits="userSpaceOnUse"
                                                                    x={0}
                                                                    y={0}
                                                                    width={24}
                                                                    height={24}
                                                                >
                                                                    <rect width={24} height={24} fill="#002FA7" />
                                                                </mask>
                                                                <g mask="url(#mask0_361_16694)">
                                                                    <path
                                                                        d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
                                                                        fill="#000C2A"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <select
                                                            className="form-select w-auto rounded-5 border border-secondary fs-7 fw-400 month-select-drop"
                                                            aria-label="Default select example"
                                                        >
                                                            <option selected="">October 2020</option>
                                                            <option value={1}>October 2022</option>
                                                            <option value={2}>October 2023</option>
                                                            <option value={3}>October 2024</option>
                                                        </select>
                                                    </div>
                                                    <div className="card-body p-4">
                                                        <div className="overflow-auto">
                                                            <div className="barchart">
                                                                <BarChart2 />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card border-0">
                                                    <div className="card-body p-4">
                                                        <div className="d-md-flex justify-content-between align-items-center">
                                                            <div>
                                                                <p className="text-black fw-600 fs-18 mb-4">
                                                                    Types Of Courses Taken
                                                                </p>
                                                                <div className="d-flex align-items-start gap-2">
                                                                    <h4 className="text-black fw-400 mb-0">8</h4>
                                                                    <span className="text-black fs-6 fw-400 mb-0">
                                                                        courses
                                                                    </span>
                                                                </div>
                                                                <p className="text-muted fs-7 fw-400">of 20,050</p>
                                                            </div>
                                                            <div className="chart-demo d-flex d-md-block justify-content-center">
                                                                <DoughnutChart />
                                                            </div>
                                                        </div>
                                                        <div className="progress-main-2 mt-4">
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    Compliance
                                                                </p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    3 courses
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress01" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">GDPR</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    2 courses
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress02" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">Skills</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    2 course
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress03" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">Fun</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    1 course
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress04" />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                                <p className="text-muted fw-400 fs-7 mb-0">Other</p>
                                                                <p className="text-muted fw-400 fs-7 mb-0">
                                                                    1 course
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="progress progress-height01 mb-4"
                                                                role="progressbar"
                                                                aria-label="Basic example"
                                                                aria-valuenow={0}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            >
                                                                <div className="progress-bar progress04" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card border-0 mt-4">
                                                    <div className="card-body p-4">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="text-black fw-600 fs-18 mb-4">
                                                                Monthly Sign Ups
                                                            </p>
                                                        </div>
                                                        <div className="overflow-auto">
                                                            <div className="areachart">
                                                                <AreaChart />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row mb-5 g-4">
                                            <TopPerformers />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default BusinessDashbaordHome
