import React from 'react'
import { Doughnut } from 'react-chartjs-2';
function DoughnutChart() {
    const doughnutOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: '80%',
        animation: {
            animateRotate: true,
            animateScale: true,
        },
    };

    const doughnutData = {
        labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5'],
        datasets: [
            {
                data: [200, 150, 100, 100, 120],
                backgroundColor: ['#D2E23D', '#FFBACC', '#FF5A59', '#FFC775', '#B8C4FF'],
                borderWidth: 2, // Set the borderWidth to add a gap between segments
            },
        ],
    };
    return (
        <>
            <Doughnut height={144} width={144} data={doughnutData} options={doughnutOptions} />
        </>
    )
}

export default DoughnutChart