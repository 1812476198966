import React from 'react'
import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection

const AccessCourse = ({ is_accessible, url }) => {
   
    const navigate = useNavigate()

    const redirectUrl = () => {
        if (url !== null) {
            navigate(url)
            // window.location.href = url; // Full page reload to the URL
        }
    };

    return (
        <>

            {is_accessible ?

                <button
                    onClick={redirectUrl}
                    type="button"
                    className="btn-primary d-flex align-items-center gap-3 rounded-2 fw-600 fs-18 mt-4"
                >
                    Start this unicors
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={22}
                        height={14}
                        viewBox="0 0 22 14"
                        fill="none"
                    >
                        <path
                            d="M1 6.99854H21L16 1"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M21 6.99854L16 13"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </button>
                :
                <button
                    type="button"
                    className="btn-secondary text-primary fw-600 d-flex align-items-center gap-3 rounded-2 mt-4"
                >
                    Start this unicors
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={23}
                        height={14}
                        viewBox="0 0 23 14"
                        fill="none"
                    >
                        <path
                            d="M1.3335 6.99854H21.3335L16.3335 1"
                            stroke="#00237D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M21.3335 6.99854L16.3335 13"
                            stroke="#00237D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </button>
            }

        </>
    )
}

export default AccessCourse