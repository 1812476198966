// Route-related imports
import { Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// Component imports
import React, { lazy } from 'react';


import '../assets/font/font-family-Poppins.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/App.css';

import Index from '../page/Index'
import Courses from '../page/CourseList'
import CategoryCourse from '../page/CategoryCourse';
import IndividualPricing from '../page/IndividualPricing';
import Teams from '../page/Teams';
import SignUp from '../components/SignUp';
import BusinessOwnersDetails from '../components/BusinessOwnersDetails';
import BusinessUserDetails from '../components/register/BusinessUserDetails';
import BusinessSubscription from '../components/BusinessSubscription';
import BusinessPayment from '../components/BusinessPayment';
import Complete from '../components/Complete';
import Complete2 from '../components/Complete2';
import Subscription from '../components/Subscription';
import SelectSubscription from '../components/SelectSubscription';
import Payment from '../components/Payment';
import PaymentProcessed from '../components/PaymentProcessed';
import SignIn from '../components/login/SignIn';
import FeaturedDetails from '../page/FeaturedDetails';
import FeaturedQuize1 from '../page/FeaturedQuize1';
import FeaturedQuize2 from '../page/FeaturedQuize2';
import QuizeResults from '../components/QuizeResults';
import BusinessDashbaordHome from '../page/BusinessDashbaordHome';
import BusinessProgress from '../page/BusinessProgress';
import CommunityAnnouncements from '../page/CommunityAnnouncements';
import FaqQuestions from '../page/FaqQuestions';
import CommunityHelp from '../page/CommunityHelp';
import CommunityHelpCenterTopic from '../page/CommunityHelpCenterTopic';
import BusinessBookmarks from '../page/BusinessBookmarks';
import BusinessLeaderboard from '../page/BusinessLeaderboard';
import BusinessCourses from '../page/BusinessCourses';
import BusinessQuizzes from '../page/BusinessQuizzes';
import BusinessLearningGroups from '../page/BusinessLearningGroups';
import BusinessGroupView from '../page/BusinessGroupView';
import BusinessAnalytics from '../page/BusinessAnalytics';
import BusinessSettings from '../page/BusinessSettings';
import CustomerHome from '../page/CustomerHome';
import CustomerMyProgress from '../page/CustomerMyProgress';
import CustomerBookmarks from '../page/CustomerBookmarks';
import CustomerLeaderboard from '../page/CustomerLeaderboard';
import CustomerCourses from '../page/CustomerCourses';
import CustomerQuizzes from '../page/CustomerQuizzes';
import ContentHome from '../page/ContentHome';
import ContentUpload from '../page/ContentUpload';
import ContentPreview from '../page/ContentPreview';
import ContentQuestions from '../page/ContentQuestions';
import ContentMultifileQuizzes from '../page/ContentMultifileQuizzes';
import ContentCourses from '../page/ContentCourses';
import ContentQuizzes from '../page/ContentQuizzes';
import CoursesExplore from '../page/CoursesExplore';
import CourseLevel from '../page/CourseLevel';
import CategoryExplore from '../page/CategoryExplore';
import GoldCourses from '../page/GoldCourses';
import Compilance from '../page/Compilance';
import UsersSignUp from '../components/UsersSignUp';
import CeoManager from '../page/CeoManager';
import ComplianceOfficer from '../page/ComplianceOfficer';
import Dpos from '../page/Dpos';
import Employe from '../page/Employe';
import SriLankaCourse from '../page/SriLankaCourse';
import CourseDetails from '../page/CourseDetails';
import SrilLankaCourseQuize1 from '../page/SrilLankaCourseQuize1';
import SrilLankaCourseQuize2 from '../page/SrilLankaCourseQuize2';
import FeaturedGoldCourse from '../page/FeaturedGoldCourse';
import FeaturedGoldCourseDetails from '../page/FeaturedGoldCourseDetails';
import FeaturedGoldCourseQuize1 from '../page/FeaturedGoldCourseQuize1';
import FeaturedGoldCourseQuize2 from '../page/FeaturedGoldCourseQuize2';
import CustomerHomeExplore from '../page/CustomerHomeExplore';
import { ProtectedLogin, ProtectedQuize, ProtectedQuizeresult, ProtectedRole } from './ProtectedQuize';
import Otp from '../page/Otp';
import ProtecdtedOtp from './ProtecdtedOtp';
import BulkUpload from '../page/BulkUpload';
import AssignCourse from '../page/AssignCourse';
import BusinessCompliance from '../page/BusinessCompliance';
import EmployeeProfile from '../page/EmployeeProfile';
import EmployeesProgress from '../page/EmployeesProgress';
import EmployeeReport from '../page/EmployeeReport';
import BusinessFamily from '../page/BusinessFamily';
import ChangePassword from '../components/login/ChangePassword';
import EditProfile from '../page/EditProfile';
import UpdateAssociatedCode from '../components/login/UpdateAssociatedCode';
import PrivateCourse from './PrivateCourse';
import DataPrivacy from '../page/DataPrivacy';
import Imprint from '../page/Imprint';
import TermsAndCondition from "../page/TermsAndCondition"
import ParentDetail from '../page/ParentDetails';
import ParentApproval from '../page/ParentApproval';
import RejectKid from '../page/RejectKid';
import KidsAgeConfirm from '../page/KidsAgeConfirm';
import Feedback from '../components/Feedback';
// eslint-disable-next-line import/no-anonymous-default-export
export default [

  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/courses",
    element: <Courses />,
  },
  // {
  //   path: "/category",
  //   element: <CategoryCourse />,
  // },
  {
    path: "/individuals",
    element: <IndividualPricing />,
  },
  {
    path: "/teams",
    element: <Teams />,
  },
  {
    path: "/announcements",
    element: <CommunityAnnouncements />,
  },
  {
    path: "/faq-questions",
    element: <FaqQuestions />,
  },
  {
    path: "/featured-details",
    element: <FeaturedDetails />,
  },

  {
    path: "/quiz",
    element: <ProtectedQuize> <FeaturedQuize1 /> </ProtectedQuize>,
  },

  {
    path: "/featured-quize-1",
    element: <FeaturedQuize1 />,
  },
  {
    path: "/featured-quize-2",
    element: <FeaturedQuize2 />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/quize-results",
    element: <ProtectedQuizeresult><QuizeResults /></ProtectedQuizeresult>,
  },
  {
    path: "/sign-up",
    element: <ProtectedLogin> <SignUp /> </ProtectedLogin>,
  },
  {
    path: "/sign-in",
    element: <ProtectedLogin> <SignIn /> </ProtectedLogin>,
  },
  {
    path: "/business-owners-details",
    element: <BusinessOwnersDetails />,
  },
  {
    path: "/user-details",
    element: (
      <ProtectedLogin>
        <ProtectedRole>
          <BusinessUserDetails />
        </ProtectedRole>
      </ProtectedLogin>
    ),
  },
  {
    path: "/business-subscription",
    element: <BusinessSubscription />,
  },
  {
    path: "/business-payment",
    element: <BusinessPayment />,
  },
  {
    path: "/complete",
    element: <Complete />,
  },
  {
    path: "/complete2",
    element: <Complete2 />,
  },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {

    path: "/select-subscription",
    element: <SelectSubscription />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },

  {
    path: "/processed",
    element: <PaymentProcessed />,
  },
  {
    path: "/community-help",
    element: <CommunityHelp />,
  },
  {
    path: "/community-help-center-topic",
    element: <CommunityHelpCenterTopic />,
  },
  {
    path: "/business-home",
    element: <PrivateRoute role={1}> <BusinessDashbaordHome /> </PrivateRoute>,
  },
  {
    path: "/business-progress",
    element: <BusinessProgress />,
  },
  {
    path: "/business-bookmarks",
    element: <BusinessBookmarks />,
  },
  {
    path: "/business-leaderboard",
    element: <BusinessLeaderboard />,
  },
  {
    path: "/business-courses",
    element: <PrivateCourse> <BusinessCourses /> </PrivateCourse>,
  },
  {
    path: "/business-quizzes",
    element: <BusinessQuizzes />,
  },
  {
    path: "/business-learning-groups",
    element: <BusinessLearningGroups />,
  },
  {
    path: "/business-group-view",
    element: <BusinessGroupView />,
  },
  {
    path: "/business-analytics",
    element: <BusinessAnalytics />,
  },
  {
    path: "/business-settings",
    element: <BusinessSettings />,
  },
  {
    path: "/business-compliance",
    element: <BusinessCompliance />,
  },
  {
    path: "/employees-progress",
    element: <EmployeesProgress />,
  },
  {
    path: "/employee-profile",
    element: <EmployeeProfile />,
  },
  {
    path: "/employee-report",
    element: <EmployeeReport />,
  },
  // {
  //   path: "/business-family",
  //   element: <PrivateRoute role={6}>  <BusinessFamily /> </PrivateRoute>,
  // },
  {
    path: "/business-family",
    element: <BusinessFamily />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/edit-profile",
    element: <PrivateCourse> <EditProfile /> </PrivateCourse>,
  },
  {
    path: "/update-associated-code",
    element: <UpdateAssociatedCode />,
  },
  {
    path: "/customer-home",
    element: <PrivateRoute role={2}> <CustomerHome /> </PrivateRoute>,
  },
  {
    path: "/customer-home-explore",
    element: <CustomerHomeExplore />,
  },
  {
    path: "/customer-my-progress",
    element: <CustomerMyProgress />,
  },
  {
    path: "/customer-bookmarks",
    element: <CustomerBookmarks />,
  },
  {
    path: "/customer-leaderboard",
    element: <CustomerLeaderboard />,
  },
  {
    path: "/customer-courses",
    element: <PrivateCourse> <CustomerCourses /> </PrivateCourse>,
  },
  {
    path: "/customer-quizzes",
    element: <CustomerQuizzes />,

  },

  {
    path: "/conten-home",
    element: <ContentHome />,
  },
  {
    path: "/conten-upload",
    element: <ContentUpload />,
  },
  {
    path: "/conten-preview",
    element: <ContentPreview />,
  },

  {
    path: "/conten-questions",
    element: <ContentQuestions />,
  },
  {

    path: "/content-uploader-quizzes-multifile-preview",
    element: <ContentMultifileQuizzes />,
  },
  {
    path: "/content-courses",
    element: <ContentCourses />,
  },
  {
    path: "/content-quizzes",
    element: <ContentQuizzes />,
  },
  // {
  //   path: "/courses-category",
  //   element: <CoursesExplore />,
  // },
  {
    path: "/courses-category/:slug",
    element: <CoursesExplore />,
  },
  {
    path: "/courses-level",
    element: <CourseLevel />,
  },

  {
    path: "/courses-explore",
    element: <CoursesExplore />,
  },

  // {
  //   path: "/courses-explore",
  //   element: <CategoryExplore />,
  // },

  {

    path: "/gold-courses",
    element: <GoldCourses />,
  },
  {
    path: "/compilance",
    element: <Compilance />,
  },


  {
    path: "/users-sign-up",
    element: <ProtectedLogin>
      <ProtectedRole>
        <UsersSignUp />
      </ProtectedRole>
    </ProtectedLogin>,
  },
  {

    path: "/manager-ceo",
    element: <CeoManager />,
  },
  {
    path: "/compliance-officer",
    element: <ComplianceOfficer />,
  },
  {
    path: "/dpos",
    element: <Dpos />,
  },
  {

    path: "/employe",
    element: <Employe />,
  },

  {
    path: "/sri-lanka-course",
    element: <SriLankaCourse />,
  },
  {
    path: "/course-details/:id",
    element: <CourseDetails />,
  },
  {

    path: "/sri-lanka-course-quize-1",
    element: <SrilLankaCourseQuize1 />,
  },
  {
    path: "/sri-lanka-course-quize-2",
    element: <SrilLankaCourseQuize2 />,
  },
  {
    path: "/gold-course",
    element: <FeaturedGoldCourse />,
  },
  {
    path: "/gold-course-details",
    element: <FeaturedGoldCourseDetails />,
  },
  {

    path: "/gold-course-quize-1",
    element: <FeaturedGoldCourseQuize1 />,
  },
  {
    path: "/gold-course-quize-2",
    element: <FeaturedGoldCourseQuize2 />,
  },
  {
    path: "/bulk-upload",
    element: <BulkUpload />,
  },
  {
    path: "/assign-course",
    element: <AssignCourse />,
  },

  {
    path: "/verify-email",
    element: <ProtecdtedOtp> <Otp /> </ProtecdtedOtp>,
  },

  {
    path: "/kids-age-confirm",
    element: <ProtecdtedOtp> <KidsAgeConfirm /> </ProtecdtedOtp>,
  },

  {
    path: "/parent-details",
    element: <ProtecdtedOtp> <ParentDetail /> </ProtecdtedOtp>,
  },

  {
    path: "/parent-approve",
    element: <ParentApproval />,
  },

  {
    path: "/parentsshouldallowkidstobepartofunicorso",
    element: <RejectKid />,
  },

  {
    path: "/data-privacy",
    element: <DataPrivacy />,

  },

  {
    path: "/imprint",
    element: <Imprint />,
  },
  {
    path: "/terms-condition",
    element: <TermsAndCondition />,
  },

  {
    path: "*",
    element: <Navigate to="/" />,
  },
];


